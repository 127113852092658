import React, { useEffect, useState } from 'react';
import { CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { useNavigate } from 'react-router-dom';
import StyledTypography from '../component/StyledTypography';

import 'react-circular-progressbar/dist/styles.css';
import './LogoutPage.css';
import { Button } from '@mui/material';

const COUNTER_SECS = 3;
const INTERVAL_MS = 100;

export type LogoutPageProps = {

    setAuthenticated: (token: string|undefined) => void,
};

export function LogoutPage0(props: LogoutPageProps) {

    const navigate = useNavigate();
    props.setAuthenticated(undefined);
    navigate('/login', {replace: true});
    return(<></>);
}